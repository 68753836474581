import { $themeColors } from '@themeConfig'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'

const chartOptions = ({
  paddingButton = 30,
  variant = 'success',
  fontSize = '2.86rem',
} = {}) => ({
  chart: {
    height: 245,
    type: 'radialBar',
    sparkline: {
      enabled: true,
    },
    dropShadow: {
      enabled: true,
      blur: 3,
      left: 1,
      top: 1,
      opacity: 0.1,
    },
  },
  colors: [$goalStrokeColor2],
  plotOptions: {
    radialBar: {
      offsetY: -10,
      startAngle: -150,
      endAngle: 150,
      hollow: {
        size: '77%',
      },
      track: {
        background: $strokeColor,
        strokeWidth: '50%',
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          color: $textHeadingColor,
          fontSize,
          top: 0,
          fontWeight: '600',
        },
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'horizontal',
      shadeIntensity: 0.5,
      gradientToColors: [$themeColors[variant]],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: 'round',
  },
  grid: {
    padding: {
      bottom: paddingButton,
    },
  },
})

export default chartOptions
