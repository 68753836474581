<template>
  <b-card>
    <b-card-header class="pl-0 pt-0">
      <h4 class="mb-0">
        {{ title }}
      </h4>
      <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>
    <b-row
      v-for="user of items"
      :key="user.name"
    >
      <b-col sm="3">
        <div class="d-flex text-nowrap">
          <b-avatar
            variant="light-danger"
            size="20"
          >
            <span>{{ user.name.charAt(0) }}</span>
          </b-avatar>
          <div
            class="pl-1 mt-5p text-truncate"
          >
            <h4 class=" text-truncate small">
              {{ user.name }}
            </h4>
          </div>
        </div>
      </b-col>
      <b-col class="mt-8p justify-content-center">
        <div>
          <b-progress
            max="100"
            :value="CALC_PERCENTAGE({
              total: TOTAL_TIME,
              value: user.time
            })"
            :variant="variant"
          />
        </div>
      </b-col>
      <b-col
        class="text-right"
        sm="2"
        :title="user.name"
      >
        <h5>{{ CONVERT_HM(user.time) }}</h5>
      </b-col>
    </b-row>
    <hr>
  </b-card>

</template>
<script>
import {
  BCard, BProgress, BAvatar, BCardHeader, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BProgress,
    BAvatar,
    BCardHeader,
    BCardText,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: String,
      default: 'primary',
    },
    totalDays: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    TOTAL_TIME() {
      return 86400 * this.totalDays
    },
  },
}
</script>
<style scoped>
    .mt-5p {
        margin-top: 5px !important
    }
    .mt-8p {
        margin-top: 8px !important
    }
</style>
