<template>
  <div>
    <filters v-model="form" />
    <b-card v-if="!form.teamId">
      <h5 class="large">
        Please Select a Team
      </h5>
    </b-card>
    <b-overlay
      v-if="form.teamId"
      :show="isProcessing"
    >
      <work-summary :work-summary="workSummary" />

      <b-row>
        <b-col>
          <highest-productive-unproductive
            title="Highest % on productive website and apps"
            variant="success"
            :items="highestProductiveUsers"
          />
        </b-col>
        <b-col>
          <highest-productive-unproductive
            title="Highest % on unproductive website and apps"
            variant="danger"
            :items="highestUnProductiveUsers"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <work-hours
            title="Worked Most Hours"
            :items="highestWorkHours"
            :total-days="TOTAL_DAYS"
            variant="success"
          />
        </b-col>
        <b-col md="6">
          <work-hours
            title="Worked Least Hours"
            :items="leastWorkHours"
            :total-days="TOTAL_DAYS"
            variant="danger"
          />
        </b-col>
        <b-col md="6">
          <work-hours
            title="Highest % of idle minutes"
            :items="highestIdleHours"
            :total-days="TOTAL_DAYS"
            variant="warning"
          />
        </b-col>
        <b-col md="6">
          <work-hours title="Highest % of manual & mobile time" />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import useApollo from '@/plugins/graphql/useApollo'
import { BOverlay, BCard } from 'bootstrap-vue'
import WorkSummary from './components/worksummary.vue'
import HighestProductiveUnproductive from './components/highestProductiveUnproductive.vue'
import WorkHours from './components/workHours.vue'
import Filters from './components/filters.vue'

const moment = require('moment')

export default {
  components: {
    WorkSummary,
    HighestProductiveUnproductive,
    WorkHours,
    Filters,
    BOverlay,
    BCard,
  },
  data() {
    const cachedFilter = this.getCachedFilters('team-report-filter')
    return {
      workSummary: {
        totalDuration: 0,
        productiveDuration: 0,
        unproductiveDuration: 0,
        neutralDuration: 0,
      },
      highestProductiveUsers: [],
      highestUnProductiveUsers: [],
      highestWorkHours: [],
      leastWorkHours: [],
      highestIdleHours: [],
      form: {
        dateRange: {
          startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment().add('1', 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        },
        teamId: cachedFilter.teamId,
        time_zone: localStorage.timezone,
      },
      isProcessing: false,
    }
  },
  computed: {
    TOTAL_DAYS() {
      return this.GET_TIME_DIFFERENCE(this.form.dateRange.startDate, this.form.dateRange.endDate, 'days') + 1
    },
  },
  watch: {
    form: {
      handler(newValue) {
        this.storeFilterQuery('team-report-filter', { ...newValue })
        if (newValue.teamId) this.getReport()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getReport() {
      this.isProcessing = true
      useApollo.team.getTeamReport({
        uuid: this.$store.state.project.selectedProject,
        startTime: this.FORMAT_DATE(this.form.dateRange?.startDate, 'YYYY-MM-DD 00:00:00'),
        endTime: this.ADD_DAYS(this.form.dateRange?.endDate, 1, 'YYYY-MM-DD 00:00:00').start,
        keyword: this.form.teamId,
      }).then(response => {
        const { data } = response
        const teamReport = data.project.teams.data[0]
        this.workSummary = {
          totalDuration: teamReport.recordsDuration,
          productiveDuration: teamReport.productiveDuration,
          unproductiveDuration: teamReport.unproductiveDuration,
          neutralDuration: teamReport.neutralDuration,
          totalActive: teamReport.users.total,
        }

        const users = teamReport.users.data
        // highest productive
        const productiveUsers = users.map(user => ({
          name: user.name,
          time: user.recordsDuration ? Math.round((user.productiveDuration / user.recordsDuration) * 100, 2) : 0,
        }))
        this.highestProductiveUsers = productiveUsers
        // highest unproductive
        productiveUsers.sort((a, b) => b.time - a.time)
        const unProductiveUsers = users.map(user => ({
          name: user.name,
          time: user.recordsDuration ? Math.round((user.unproductiveDuration / user.recordsDuration) * 100, 2) : 0,
        }))
        unProductiveUsers.sort((a, b) => b.time - a.time)
        this.highestUnProductiveUsers = unProductiveUsers
        // most worked hours
        const highestWorkHours = users.map(user => ({
          name: user.name,
          time: user.recordsDuration,
        }))
        highestWorkHours.sort((a, b) => b.time - a.time)
        this.highestWorkHours = highestWorkHours
        // least worked hours
        const leastWorkHours = users.map(user => ({
          name: user.name,
          time: user.recordsDuration,
        }))
        leastWorkHours.sort((a, b) => a.time - b.time)
        this.leastWorkHours = leastWorkHours
        // most idle hours
        const mostIdleHours = users.map(user => ({
          name: user.name,
          time: user.idlePercent * user.recordsDuration,
        }))
        mostIdleHours.sort((a, b) => b.time - a.time)
        this.highestIdleHours = mostIdleHours
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isProcessing = false })
    },
  },
}
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/chart-apex.scss';
</style>
